import { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { HeaderWrapper } from './components/header'
import { ScrollTop } from './components/scroll-top'
import { Content } from './components/content'
import { FooterWrapper } from './components/footer'
import { Sidebar } from './components/sidebar'
import { ThemeModeProvider, } from '../partials'
import { PageDataProvider } from './core'
import { ApiService, reInitMenu } from '../helpers'
import { ToolbarWrapper } from './components/toolbar'
import { pluginsInitialization } from './MasterInit'
import { useStoreMode } from '../../app/modules/auth/core/store'
import { AuthLayoutMode } from '../../app/pages/mode/authMode'
import { ToggleComponent } from '../assets/ts/components'
import { Tab } from 'bootstrap'

const MasterLayout = () => {
  const location = useLocation()
  const storeMode = useStoreMode((state:any) => state)
  const [show,setShow] = useState(false)
  useEffect(() => {
    console.log('location.pathname',location.pathname)
    console.log('location.pathname',storeMode)
    
    if(location.pathname != '/mode') {
      if(storeMode && storeMode.info) {
        const staffInfo = JSON.parse(JSON.stringify(storeMode?.info?.staffInfo))||{}
        let tempData = {
          funcName: 'check logout staff',
          error: 'click out mode',
          staffcode: staffInfo.code
        }
        ApiService.post(`smarthub/logs/write`, tempData)
      }
      
      storeMode.setMode(null)
      // console.log('storeMode',storeMode)
    }
    else {
      if(!storeMode.info) {
        setShow(true)
      }
    }
    // pluginsInitialization()
    ToggleComponent.bootstrap()
    document.querySelectorAll('[data-bs-toggle="tab"]').forEach((tab) => {
      Tab.getOrCreateInstance(tab)
    })
    reInitMenu()
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
          <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
            <HeaderWrapper />
            <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
              <Sidebar />
              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                  <ToolbarWrapper />
                  <Content>
                    <Outlet />
                  </Content>
                </div>
                <FooterWrapper />
              </div>
            </div>
          </div>
        </div>

        {/* begin:: Drawers */}
        <AuthLayoutMode show={show} setShow={setShow}/>
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export { MasterLayout }
