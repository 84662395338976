import { getLocalStorage } from "../../theme/helpers"

export function getLocationData() {
  return { title: '', permission: '' }
}

export interface SideBarMenuItem {
  title: string
  path: string
  permission: string
  icon?: string
  children?: SideBarMenuItem[],
  defaultOpen?: boolean
  isLink?: boolean
}
// export const sideBarMenuData = (): SideBarMenuItem[] => {
export function SideBarMenuData() {
  const localAuth: any = getLocalStorage('localAuth')||{}
  let dataMenu = sideBarMenuData
  let indexMode = dataMenu.findIndex((i: any) => i.path == 'mode')
  if(indexMode > -1) {
    let mode = dataMenu[indexMode]
    let childrenMode = mode.children as any
    if(localAuth.user) {
      const currentUser = localAuth.user
      if (currentUser && currentUser.visibleto) {
        let arrVisible = currentUser.visibleto.split(',')
        childrenMode = childrenMode.filter((i: any) => arrVisible.includes(i.path))
        dataMenu[indexMode].children = childrenMode
      }
    }
  }
  
  return dataMenu
}
export const sideBarMenuData: SideBarMenuItem[] = [
  {
  title: 'Dashboard',
  path: 'dashboard',
  icon: 'bi bi-house',
  permission: ''
},
{
  title: 'Mode',
  path: 'mode',
  icon: '/media/icons/custom/request.svg',
  permission: '',
  defaultOpen: true,
  isLink: true,
  children: [
    {
      title: 'Orders Hub',
      path: 'orders-hub',
      icon: '/media/icons/custom/tnf_order_request.svg',
      permission: '',
    },
    {
      title: 'Table Service',
      path: 'table-service',
      icon: '/media/icons/custom/tnf_advance.svg',
      permission: ''
    },
    {
      title: 'Payment Terminal',
      path: 'payment-terminal',
      icon: '/media/icons/custom/tnf_payment.svg',
      permission: ''
    },
   
  ]
},
]

