import CryptoJS from "crypto-js";
import { popupMessage } from "../../app/modules/messages";
import { ApiService } from "./ApiService";
import { t } from "i18next";

const key = process.env.REACT_APP_PRIVATE_KEY

export const clientTime = {
    nowDate: new Date(),
    nowDateString: new Date().toLocaleDateString(),
    nowTimeNumber: new Date().getTime()
}

export const encryptData: (data: string, code?: string) => string | null = (data, code) => {
    if (!key) return null;
    // console.log('data',data)
    return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
}

export const decryptData: (ciphertext: string, code?: string) => string | null = (ciphertext, code) => {
    if (!key) return null;
    const bytes = CryptoJS.AES.decrypt(ciphertext,  key).toString(CryptoJS.enc.Utf8)
    // console.log('bytes',bytes)
    return bytes ? JSON.parse(bytes) : null;
}

export function ApiShowError(error: any,funcName?: any, staffInfo?: any) {
    if(error.response?.data?.errorCode != '403-284210000') {
        let textErr = error.response?.data?.errorMessage||error.message
        let option = { icon: 'error', description: textErr,} as any
        
        if(error.response?.data?.errorCode != '403-5000') {
            let tempData = {
                funcName: funcName,
                error: error.response?.data||error.message,
                staffcode: staffInfo?.code,
                time: new Date()
            }
            ApiService.post(`smarthub/logs/write`, tempData)
        }
        if(error.code == 'ECONNABORTED') {
            let callFunc = () => {
                // return redirect('/mode')
            }
            textErr = t('check-connect-internet')
            option.callFunc = callFunc
            return
        }
        popupMessage(option)
        

    }
}

export async function ApiUploadFiles(uploadcode: string, category: string, files: File[], isMessage?: boolean) {
    if (!files || files.length == 0) {
        popupMessage({ icon: "error", description: "Invalid files." })
        return undefined
    }
    // console.log('files',files)
    const formData = new FormData();
    formData.append('uploadcode', uploadcode)
    formData.append('category', category.toUpperCase())
    files.forEach(file => formData.append('file', file))
    console.log('formData',formData)
    try {
        const results = await ApiService.post(`utilities/uploadfiles`, formData)
        if (results) {
            isMessage && popupMessage({ icon: 'success', autoClose: true })
            return results.data.uploadedfiles
        }
        return undefined
    } catch (error: any) {
        popupMessage({ icon: 'error', description: error.response.data?.errorMessage })
    }
}

export async function ApiDeleteFile(pathtofile: string) {
    let tempData = {
        pathtofile: pathtofile
    }
    await ApiService.post(`utility/delete/file`, tempData)
        .then(({ data }) => { })
        .catch((error: any) => {
            popupMessage({ icon: 'error', description: error.response.data?.errorMessage })
        });
}

export function getLocalStorage<T>(key: string) {
    const ls = localStorage.getItem(key)
    if (ls) {
        try {
            return JSON.parse(ls) as T
        } catch (er) {
            console.error(er)
        }
    }
    return undefined
}

export function setLocalStorage(key: string, values: any) {
    localStorage.setItem(key, JSON.stringify(values))
}
const isClientSide = (): boolean => typeof window !== 'undefined';
export function clearUserData() {
    if (isAuth()) {
        console.error('Clear local');
        removeCookie('token');
        removeCookie('userid');
        if (isClientSide()) {
            localStorage.clear();
            window.location.href = '/login';
        }
    }
}

export function isAuth() {
    return getCookie('token') !== undefined;
}
export const getCookie = (name: string) => {
    const nameLenPlus = (name.length + 1);
    return document.cookie
      .split(';')
      .map(c => c.trim())
      .filter(cookie => cookie.substring(0, nameLenPlus) === `${name}=`)
      .map(cookie => decodeURIComponent(cookie.substring(nameLenPlus)))[0] || null;
  }
  
  export const setCookie = (name: string, value: string, days: number) => {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
    return 0
  }
  
  export const removeCookie = (name: string) => {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    return 0
  }
