import localforage from "localforage";
import { popupAlert, popupMessage } from "../../../modules/messages";
import { KITCHENSTATUS, renderItemProduction } from "../../orderManagement/tabs/templateOrder";
import { kitchenPrintEpson, orderPrintEpson, prePrintEpson } from "./templatePrint";
import { sortByNumber, unique_arr } from "../../../../theme/helpers";

export const connectPrinterWithTimeout = (dataPrint: any, timeout = 10000) => {
  return new Promise(async(resolve, reject) => {
      let ePosDev = new (window as any).epson.ePOSDevice();
      let {host, port} = dataPrint

      // Thiết lập timeout
      const timer = setTimeout(() => {
          ePosDev.disconnect(); // Ngắt kết nối nếu quá thời gian chờ
          reject(new Error('Timeout'));
      }, timeout);
      await ePosDev.connect(host, port, (data: any) => {
        // console.log('data',data)
        if (data === "OK" || data === 'SSL_CONNECT_OK') {
          ePosDev.createDevice(
            "local_printer",
            ePosDev.DEVICE_TYPE_PRINTER,
            { crypto: true, buffer: false },
            (devobj: any, retcode: any) => {
              if (retcode === "OK") {
                clearTimeout(timer); // Xóa bỏ timeout khi kết nối thành công
                resolve(devobj); // Trả về object kết nối thành công
    
              } else {
                clearTimeout(timer); // Xóa bỏ timeout khi có lỗi
                reject(new Error(`${retcode}`));
                throw retcode;
    
              }
            }
          );
        } else {
          reject(new Error(`${data}`));
          throw data;
        }
      });
      // Thực hiện kết nối đến máy in
      // ePosDev.connect(host, port, {
      //     onSuccess: (deviceObj: any) => {
      //         clearTimeout(timer); // Xóa bỏ timeout khi kết nối thành công
      //         resolve(deviceObj); // Trả về object kết nối thành công
      //     },
      //     onError: (error: any) => {
      //         clearTimeout(timer); // Xóa bỏ timeout khi có lỗi
      //         reject(new Error(`Lỗi khi kết nối máy in: ${error.status}`));
      //     }
      // });
  });
};
export const isConnectPrinter = async(dataPrint: any) => {
  let ePosDev = new (window as any).epson.ePOSDevice();
  let {host, port} = dataPrint
  // console.log('dataPrint',dataPrint)
  let isCheck = false
  await ePosDev.connect(host, port, (data: any) => {
    // console.log('data',data)
    if (data === "OK" || data === 'SSL_CONNECT_OK') {
      ePosDev.createDevice(
        "local_printer",
        ePosDev.DEVICE_TYPE_PRINTER,
        { crypto: true, buffer: false },
        (devobj: any, retcode: any) => {
          if (retcode === "OK") {
            isCheck = true

          } else {
            throw retcode;

          }
        }
      );
    } else {
      throw data;
    }
  });
  return isCheck
}
export const checkConnectPrinter = (dataPrint: any,index: any,setDataLoading: any) => {
  let ePosDev = new (window as any).epson.ePOSDevice();
  let {host, port, name} = dataPrint
  // console.log('dataPrint',dataPrint)
  ePosDev.connect(host, port, (data: any) => {
    // console.log('data',data)
    if (data === "OK" || data === 'SSL_CONNECT_OK') {
      
      ePosDev.createDevice(
        "local_printer",
        ePosDev.DEVICE_TYPE_PRINTER,
        { crypto: true, buffer: false },
        (devobj: any, retcode: any) => {
          if (retcode === "OK") {
            // printer.current = devobj;
            // let  printer = devobj;
            setDataLoading((dataList: any) => {
              dataList[index].isLoading = false
              dataList[index].returnStatus = 'Successed'
              return dataList
            })
            popupAlert(`PRINTER [${name}] [${host}] connected`, 'success')
            

            // setConnectionStatus(STATUS_CONNECTED);
          } else {
            popupAlert(`PRINTER [${name}] [${host}] ${retcode}`, 'info')
            setDataLoading((dataList: any) => {
              dataList[index].isLoading = false
              dataList[index].returnStatus = 'Failed '+ retcode
      
              return dataList
            })
            throw retcode;

          }
        }
      );
    } else {
      popupAlert(`PRINTER [${name}] [${host}] ${data}`, 'error')
      setDataLoading((dataList: any) => {
        dataList[index].isLoading = false
        dataList[index].returnStatus = 'Failed ' + data

        return dataList
      })
      console.log(data)
      throw data;
    }
  });
}
const connect = async(dataPrint: any) => {
  let ePosDev = new (window as any).epson.ePOSDevice();
  let {host, port,productionsectioncode,setPrinterlist, PrinterList} = dataPrint
  ePosDev.connect(host, port, (data: any) => {
    // console.log('data',data)
    if (data === "OK" || data === 'SSL_CONNECT_OK') {
      ePosDev.createDevice(
        "local_printer",
        ePosDev.DEVICE_TYPE_PRINTER,
        { crypto: true, buffer: false },
        (devobj: any, retcode: any) => {
          if (retcode === "OK") {
            // printer.current = devobj;
            // let  printer = devobj;
            let dataInfo = {
              printer: devobj,
              ePosDev: ePosDev,
              productionsectioncode: productionsectioncode
            }
            savePrintlist(dataInfo, PrinterList, setPrinterlist)

            // setConnectionStatus(STATUS_CONNECTED);
          } else {
            throw retcode;
          }
        }
      );
    } else {
      throw data;
    }
  });
}
export const connectEpson = async(storePrint: any) => {
  let merchantsData = await localforage.getItem('merchantsData')||{} as any
  let {sectiondispstatmappings} = merchantsData
  sectiondispstatmappings.forEach((item: any) => {
    connect({
      host: item.PrinterList?.host,
      port: item.PrinterList?.port,
      productionsectioncode: item.productionsectioncode,
      setPrinterlist: storePrint.setPrinterlist,
      PrinterList: storePrint.PrinterList,
    })
  })
 
  
};
const savePrintlist = async(dataInfo: any, list: any,setPrinterlist: any) => {
  
  let PrinterList = JSON.parse(JSON.stringify(list))||[]
  let index = PrinterList.findIndex((i: any) => i.productionsectioncode == dataInfo.productionsectioncode) 
  if(index > -1) {
    PrinterList[index] = dataInfo
  }
  else {
    PrinterList.push(dataInfo)
  }
  // console.log('PrinterList',PrinterList)
  
  // const storePrint = useStorePrint((state:any) => state)
  setPrinterlist(PrinterList)
  // localforage.setItem('printerList', PrinterList)
}
const funcReturnTempplatePrint = (dataPrint: any, type: any, printer: any, ePosDev: any) => {
  try {
    if(type == 1) {
      orderPrintEpson(printer,ePosDev,dataPrint)
    }
    if(type == 2) {
      kitchenPrintEpson(printer,ePosDev,dataPrint)
    }
    else if (type == 3) {
      prePrintEpson(printer,ePosDev,dataPrint)
    }
  } catch (error) {
    console.log('error',error)
  }
  
}
const removeLogsPrinter = (logsPrinter: any) => {
  let rank = 7*24*60*60*100 // logs in 7 days need reset
  let temp = logsPrinter.map((i: any,index: number) =>{
    let a = i.split(': #')[0]
    return {
      index: index,
      value: new Date(a).getTime(),
      label: i, 
    }
  })
  temp = sortByNumber(temp,'value')
  // console.log('temp',temp)
  let b = temp[0]?.value + rank
  temp = temp.filter((i: any) => i.value > b)
  // console.log('temp',temp)
  if(temp.length != 0) {
    let c = sortByNumber(temp,'value','DESC')
    return c.map((i: any) => i.label)
  }
  return logsPrinter
}
const setLogPrinter = async(info: any, text: any,isAlert?: any) => {
  let logsPrinter = await localforage.getItem('logsPrinter')||[] as any
  if(logsPrinter.length != 0) {
    logsPrinter = removeLogsPrinter(logsPrinter)
  }
  let time = new Date().toLocaleString('en-GB',{
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })
  if(isAlert) {
    popupAlert(`${time}: #${info.hosptransactioncode}[${info.tableno}] [${info.code}] [${info.host}] ${text}`,'error')
  }
  localforage.setItem('logsPrinter', [`${time}: #${info.hosptransactioncode}[${info.tableno}] [${info.code}] [${info.host}] ${text}`].concat(logsPrinter))
  
}
export const connectPrintEpson = async(dataEpson: any,setPrinterlist: any) => {
  let dataPrint = dataEpson.data
  // console.log('aaaaaaaaaaaa',dataEpson)
  let PrinterList = dataEpson.PrinterList||[]
  let type = dataEpson.type
  let printerPort = dataEpson.printConfig?.port
  let printerIPAddress = dataEpson.printConfig?.host

  let ePosDevice = {} as any;
  let printer = {} as any;
  let tempDataLog = {
    code: dataPrint.productionsectioncode,
    ...dataEpson.printConfig,
    hosptransactioncode: dataPrint.code,
    tableno: dataPrint.tableno

  }
  if (!printerIPAddress) {
    setLogPrinter(tempDataLog,'IP address not found',true)
    // setConnectionStatus("Type the printer IP address");

    return false;
  }
  if (!printerPort) {
    // setConnectionStatus("Type the printer port");
    setLogPrinter(tempDataLog,'PORT not found',true)
    
    return false;
  }

  setLogPrinter(tempDataLog,'Connecting ...')

  let ePosDev = new (window as any).epson.ePOSDevice();
  
  ePosDevice.current = ePosDev;

  ePosDev.connect(printerIPAddress, printerPort, (data: any) => {
    // console.log('data',data)
    if (data === "OK" || data === 'SSL_CONNECT_OK') {
      setLogPrinter(tempDataLog,'Send')

      ePosDev.createDevice(
        "local_printer",
        ePosDev.DEVICE_TYPE_PRINTER,
        { crypto: true, buffer: false },
        async (devobj: any, retcode: any) => {
          if (retcode === "OK") {
            printer = devobj;
            let dataInfo = {
              printer: devobj,
              ePosDev: ePosDev,
              productionsectioncode: dataPrint.productionsectioncode
            }
            // await savePrintlist(dataInfo,PrinterList,setPrinterlist)
            funcReturnTempplatePrint(dataPrint,type,printer,ePosDev)
            setLogPrinter(tempDataLog,`Print Success`)

            // setConnectionStatus(STATUS_CONNECTED);
          } else {
            setLogPrinter(tempDataLog,`Send Failed (${retcode})`,true)

            // console.log('retcode',retcode)

            throw retcode;
          }
        }
      );
    } else {
      setLogPrinter(tempDataLog,`Send error (DEVICE_NOT_FOUND)`,true)
      // console.log('data',data)
      throw data;
    }
  });
  return printer
};
export function disconnect(printer: any,ePosDev: any) {
  //Discards the Printer object
  ePosDev.deleteDevice(printer, ePosDev.disconnect());
}


export const findAndPrint = async(itemLines: any,dataModal: any,dataStore: any, preifix?: any) => {
  let dataRender = renderItemProduction(unique_arr(itemLines,'lineno'))
  // console.log('dataRender',dataRender)
  // console.log('itemLines',itemLines)
  formatDataPrint(dataRender,dataModal,dataStore,preifix)
}
export const prePrintReceipt = async(itemLines: any,dataModal: any,dataStore: any, preifix?: any) => {
  let dataRender = [{
    items: unique_arr(itemLines,'lineno'),
    name: 'RECEIPT'
  }]
  formatDataPrint(dataRender,dataModal,dataStore,preifix)
}
const formatDataPrint = async(dataRender: any,dataModal: any,dataStore: any, preifix?: any) => {
  let {staffInfo, setPrinterlist, PrinterList} = dataStore
  let merchantsData = await localforage.getItem('merchantsData')||{} as any
  let {productionsections, sectiondispstatmappings} = merchantsData
  let sectionid =  dataModal.sectionid
  let typePrint = 2 // default print ['BAR','KITCHEN']
  dataRender.forEach((i: any) => {
    i.items = i.items.map((item: any) => {
        let tempPreifix = preifix
        // console.log('item',item)
        if(item.kitchenstatus == KITCHENSTATUS.voided || (item.kotline && item.kotlines?.isvoided)){
            // console.log('hủy mons',item)
            tempPreifix = 'HỦY MÓN'
        }
        return {...item,prefix: tempPreifix?tempPreifix:''}
    })
    console.log('productionsections',productionsections)
   let code = productionsections?.find((p: any) => p.type == i.name)?.code
   console.log('code',code)
   if(code) {
    if(code == 'PREPRINT') {
      typePrint = 3
    }
    let printInf = sectiondispstatmappings?.find((s: any) => s.hospdiningareasectionid == sectionid && s.productionsectioncode == code)?.PrinterList
    // console.log('printInf',printInf)
    let store = {}
    if(dataModal.user) {
      store = dataModal.user||{}
    }
    if(printInf) {
        let printData = {
            data: {
                ...dataModal,
                dataInfo: {
                  ...dataModal.dataInfo,
                  staffInfo: staffInfo,
                },
                store: store,
                HospTransactionLines: i.items,
                name: code == 'PREPRINT' ? 'Kiểm Món' : code,
                productionsectioncode: code,
                nameonreceipt: staffInfo.nameonreceipt,
            },
            printConfig: {
                host: printInf.host,
                port: printInf.port
            },
            type: typePrint,
            setPrinterlist: setPrinterlist,
            PrinterList: PrinterList,
            
        }
        console.log('printData',printData)
        let findPrint = PrinterList?.find((i: any) => i.productionsectioncode == code)
        if(findPrint && false) {
          console.log('findPrint',findPrint)
        //   findPrint.printer?.status((statusObj: any) => {
        //     if (statusObj.connection) {
        //         console.log('Máy in đang kết nối và hoạt động bình thường');
        //     } else {
        //       console.log('Mất kết nối với máy in');
        //     }
        // });
          funcReturnTempplatePrint(printData.data,typePrint,findPrint.printer,findPrint.ePosDev)
        }
        else {
          connectPrintEpson(printData,setPrinterlist)
        }
    }
    else {
        popupMessage({description:"Please check config printer!!", icon: 'info'})
    }
   }
   else {
    popupMessage({description:"Please check setup productionsections item!!", icon: 'info'})
}
})
}
